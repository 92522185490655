import {
    booleanAttribute,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    numberAttribute,
    Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent, TIcon } from '@shared/components/icon/icon.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { TColor, TFontSize } from '@shared/interfaces/global.interface';
import { translations } from '@shared/utils/translations';

@Component({
    standalone: true,
    selector: 'nothing-found',
    template: `
        <div class="nothing-found flex-column align-center-center height-100 {{ color }}">
            <icon [name]="icon" [color]="color" [size]="iconSize" />

            @if (message) {
                <div
                    class="text-uppercase message"
                    [style.font-size]="fontSize"
                    [style.color]="customColor"
                    [class.item-margin-bottom]="!!messageHint">
                    {{ message | translate }}
                </div>
            }
            @if (messageHint) {
                <div
                    class="text-uppercase message-hint text-center"
                    [style.font-size]="hintFontSize">
                    {{ messageHint | translate }}
                </div>
            }
        </div>

        @if (showTryAgain) {
            <div class="align-center-center">
                <custom-button
                    class="item-margin-top"
                    type="stroked"
                    [label]="translations.global.try_again"
                    icon="autorenew"
                    color="primary"
                    (click)="tryAgain.emit()" />
            </div>
        }
    `,
    styles: [
        `
            :host {
                background-color: var(--primary-layer-color);
                border-radius: var(--border-radius);
                display: block;
            }

            .nothing-found .message {
                font-weight: bold;
                color: var(--primary-color);
            }

            .nothing-found .message-hint {
                white-space: pre-line;
            }

            .nothing-found.accent .message {
                color: var(--accent-color);
            }

            .nothing-found.warn .message {
                color: var(--error-color);
            }

            .nothing-found.alert .message {
                color: var(--alert-color);
            }
        `,
    ],
    imports: [TranslateModule, IconComponent, MatProgressSpinnerModule, CustomButtonComponent],
})
export class NothingFoundComponent {
    @Input({ required: true }) icon: TIcon;
    @Input({ required: true }) message: string | null;
    @Input() messageHint?: string;
    @Input() fontSize: TFontSize = 'large';
    @Input() hintFontSize: TFontSize = 'normal';
    @Input({ transform: numberAttribute }) iconSize: number = 80;
    @Input() color: TColor = 'primary';
    @Input() customColor: string;
    @Input() bgColor: string;
    @Input({ transform: booleanAttribute }) showTryAgain: boolean;
    @Input({ transform: booleanAttribute, alias: 'remove-padding' }) removePadding: boolean;

    @Output() tryAgain: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('style')
    get style() {
        return {
            padding: this.removePadding ? '' : 'var(--container-padding)',
        };
    }

    protected readonly translations = translations;
}
