import { Component, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    standalone: true,
    selector: 'destroyer-component',
    template: ``,
})
export class DestroyerComponent implements OnDestroy {
    private destroySubscriptions$: Subject<void> = new Subject<void>();

    takeUntilDestroyed<T>(): MonoTypeOperatorFunction<T> {
        return takeUntil<T>(this.destroySubscriptions$);
    }

    ngOnDestroy(): void {
        this.destroySubscriptions$.next();
        this.destroySubscriptions$.unsubscribe();
    }
}
