import {
    IUserData,
    IUserDataDto,
    IUserDataRoleDto,
    TUserEnv,
} from '@shared/interfaces/user-data.interface';
import { GenericModel } from '@shared/models/generic.model';
import { TUserData } from '@shared/interfaces/user-data-role.interface';

export class UserDataModel extends GenericModel<IUserData, IUserDataDto> implements IUserData {
    role: UserDataRoleModel;
    email: string;
    isLoggedIn: boolean;
    allowLanding: boolean;
    profilePicture: string;
    sub: string;
    username: string;
    appVersion: string;
    environment: TUserEnv;

    constructor(user?: IUserDataDto) {
        super(user, UserDataModel);

        this.username = user?.username || '';
        this.email = user?.email || '';
        this.sub = user?.sub || '';
        this.appVersion = user?.appVersion || '';
        this.environment = user?.environment || 'local';
        this.role = new UserDataRoleModel(user?.role);

        this.profilePicture = '';
        this.isLoggedIn = false;
        this.allowLanding = false;
    }
}

export class UserDataRoleModel extends GenericModel<IUserDataRoleDto> implements IUserDataRoleDto {
    admin: boolean;
    user: boolean;

    constructor(role?: IUserDataRoleDto) {
        super(role, UserDataRoleModel);

        this.admin = role?.admin || false;
        this.user = role?.user || false;
    }

    get userType(): TUserData {
        if (this.admin) return 'admin';
        return 'user';
    }
}
