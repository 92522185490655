import {
    booleanAttribute,
    Component,
    HostBinding,
    HostListener,
    inject,
    Input,
    Signal,
} from '@angular/core';
import { IconComponent } from '@shared/components/icon/icon.component';
import { CustomCardService } from '@shared/components/custom-card/custom-card.service';

@Component({
    standalone: true,
    selector: 'custom-card-header',
    template: `
        <ng-content></ng-content>
        @if (!removeIconExpand) {
            <icon name="chevron_down" [rotate]="state()" rotateDeg="-180" />
        }
    `,
    styleUrls: ['./custom-card-header.component.scss'],
    imports: [IconComponent],
})
export class CustomCardHeaderComponent {
    @Input({ transform: booleanAttribute }) removeIconExpand: boolean;
    @Input({ alias: 'align-center', transform: booleanAttribute }) alignCenter: boolean;

    private customCardService: CustomCardService = inject(CustomCardService);

    state: Signal<boolean> = this.customCardService.state;

    @HostBinding('style')
    get style() {
        return {
            'cursor': this.removeIconExpand ? '' : 'pointer',
            'display': 'flex',
            'align-items': 'center',
            'place-content': this.alignCenter ? 'center' : 'center flex-start',
        };
    }

    @HostListener('click', ['$event'])
    click(event: Event) {
        if (this.removeIconExpand) return;

        this.customCardService.trigger();

        event.stopPropagation();
        event.preventDefault();
    }
}
