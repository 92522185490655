import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[app-background]',
})
export class AppBackground {
    @HostBinding('class')
    get class() {
        return 'app-background';
    }
}

@Directive({
    standalone: true,
    selector: '[primary-layer-color]',
})
export class PrimaryLayerColor {
    @HostBinding('class')
    get class() {
        return 'primary-layer-color';
    }
}

@Directive({
    standalone: true,
    selector: '[secondary-layer-color]',
})
export class SecondaryLayerColor {
    @Input({ alias: 'secondary-layer-color', transform: booleanAttribute }) state: boolean = true;

    @HostBinding('class')
    get class() {
        return this.state ? 'secondary-layer-color' : '';
    }
}

@Directive({
    standalone: true,
    selector: '[tertiary-layer-color]',
})
export class TertiaryLayerColor {
    @Input({ alias: 'tertiary-layer-color', transform: booleanAttribute }) state: boolean = true;

    @HostBinding('class')
    get class() {
        return this.state ? 'tertiary-layer-color' : '';
    }
}

@Directive({
    standalone: true,
    selector: '[fourth-layer-color]',
})
export class FourthLayerColor {
    @HostBinding('class')
    get class() {
        return 'fourth-layer-color';
    }
}

@Directive({
    standalone: true,
    selector: '[fifth-layer-color]',
})
export class FifthLayerColor {
    @HostBinding('class')
    get class() {
        return 'fifth-layer-color';
    }
}

@Directive({
    standalone: true,
    selector: '[footer-color]',
})
export class FooterColor {
    @HostBinding('class')
    get class() {
        return 'footer-color';
    }
}

@Directive({
    standalone: true,
    selector: '[side-menu-color]',
})
export class SideMenuColor {
    @HostBinding('class')
    get class() {
        return 'side-menu-color';
    }
}

@Directive({
    standalone: true,
    selector: '[transparent-color]',
})
export class TransparentColor {
    @Input({ alias: 'transparent-color', transform: booleanAttribute }) state: boolean = true;

    @HostBinding('style.background')
    get style() {
        return this.state ? 'transparent' : '';
    }
}
