export const environment = {
    production: false,
    name: 'Office 300',
    localStorageSuffix: 'office300',
    mockService: true,
    api: '/api',
    aws: {
        aws_project_region: 'eu-west-1',
        aws_cognito_region: 'eu-west-1',
        aws_user_pools_id: 'eu-west-1_7leSctsEk',
        aws_user_pools_web_client_id: '3p08ap3ffhi5ufv7mgrkcs87un',
        oauth: {
            domain: 'info300-qa.auth.eu-west-1.amazoncognito.com',
            scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'https://info300mgr1-dps.kone.com/',
            redirectSignOut: 'https://info300mgr1-dps.kone.com/',
            responseType: 'code',
        },
        provider: 'KONE-AD-SSO-qa-OIDC-IdP',
    },
};
